import React, { useState } from "react";
import {
  Container,
  Box,
  Paper,
  Stack,
  Typography,
  Grid,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import PaymentDetails from "./PaymentDetails";
import { makeStyles, Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { makeorderpaid } from "../../../api";
const pKey =
  "pk_test_51JdB2hSIhPZY1ESZZxmO2tKFzdkVBsRo9aMtxEZ1x6W9WQaXepw3iR43QeQZPxuUKqldxEFnzqjKzrQC3PbE7X8200LRIhhTDz";
const sKey =
  "sk_test_51JdB2hSIhPZY1ESZjW9hFd6mBfX8qyZpAyfVPJaiXg8d5g3Kkf2P1Q06bAZ41TtdiOspYRScdvdu5H9Wr1rNNJzT00C54dPw55";

const stripePromise = loadStripe(pKey);

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      flex: "unset !important",
      margin: "0 0 16px !important",
    },
  },
  fullPaid: {
    color: "green",
  },
}));

const Payment = ({ order, loading, id, paidOrder }) => {
  const classes = useStyles();
  const [payment, setPayment] = useState(false);
  const [paymentLink, setPaymentLink] = useState(false);
  const [copy, setCopy] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleManualPayment = () => {
    setPayment(true);
  };

  const handlePaymentLink = () => {
    setPaymentLink(true);
  };

  function copyURL(e) {
    e.preventDefault();
    let link = document.getElementById("payment-link");
    navigator.clipboard.writeText(link).then(
      () => {
        /* clipboard successfully set */
        setCopy(true);
      },
      () => {
        /* clipboard write failed */
      }
    );
  }

  const handlePaymentChange = async (id, status) => {
    const confirmAction = window.confirm(
      "Are you sure you want to mark this order as paid?"
    );
    if (!confirmAction) return;
    setLoader(true);
    const userid = localStorage.getItem("auth_id");
    var data = new FormData();
    data.append("orderstatus", "processing");
    data.append("userid", userid);
    const paidorder = await makeorderpaid(id, data);
    setLoader(false);
    if (paidorder) {
      window.location.reload();
    }

    //setPaidOrder("paid");
  };
  return (
    <Container className={classes.container}>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="50vh"
          width="50vw"
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {order && (
        <>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              mb: 2,
            }}
          >
            Payment
            {/* (
            <span style={{ fontSize: "16px" }}>
              {order.transaction_id ? "Paid" : "Pending"}
            </span>
            ) */}
          </Typography>
          <Stack direction="row" flexWrap="wrap" spacing={2}>
            <Box sx={{ flex: 1 }} className={classes.fullWidth}>
              <Paper sx={{ padding: "2rem 1rem" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "1.5em",
                    mb: 2,
                  }}
                >
                  Order #{order.id}
                </Typography>
                <Grid container justifyContent="space-between">
                  <Grid item md={8} xs={6}>
                    <TypographyHeading>Product</TypographyHeading>
                  </Grid>
                  <Grid item md={1} xs={2} textAlign="center">
                    <TypographySubHeading>Price</TypographySubHeading>
                  </Grid>
                  <Grid item md={1} xs={2} textAlign="center">
                    <TypographySubHeading>Quantity</TypographySubHeading>
                  </Grid>
                  <Grid item md={1} xs={2} textAlign="center">
                    <TypographySubHeading> Total</TypographySubHeading>
                  </Grid>
                </Grid>
                <Divider />
                {order.line_items
                  ? order.line_items.map((item) => {
                      return (
                        <Grid
                          container
                          key={item.id}
                          sx={{ marginTop: "10px" }}
                          justifyContent="space-between"
                        >
                          <Grid item md={8} xs={6}>
                            <Typography
                              sx={{
                                color: "#3c434a",
                                fontSize: "13px",
                                lineHeight: "1.4em",
                                fontWeight: "bold",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <CustomTypography>sku:</CustomTypography>
                              <CustomTypography>{item.sku}</CustomTypography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <CustomTypography>Variation ID:</CustomTypography>
                              <CustomTypography>
                                {item.variation_id}
                              </CustomTypography>
                            </Box>
                            {item.meta_data[0] ? (
                              <Box display="flex" alignItems="center">
                                <CustomTypography>
                                  {item.meta_data[0].display_key}:
                                </CustomTypography>
                                <CustomTypography>
                                  {item.meta_data[0].display_value}
                                </CustomTypography>
                              </Box>
                            ) : null}
                          </Grid>
                          <Grid item md={1} xs={2} textAlign="center">
                            <CustomTypography>{item.subtotal}</CustomTypography>
                          </Grid>
                          <Grid item md={1} xs={2} textAlign="center">
                            <CustomTypography>
                              x{item.quantity}
                            </CustomTypography>
                          </Grid>
                          <Grid item md={1} xs={2} textAlign="center">
                            <CustomTypography> ${item.total}</CustomTypography>
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}
                <Grid
                  container
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                  justifyContent="space-between"
                >
                  <Grid item md={11} xs={10}>
                    <Typography
                      sx={{
                        color: "#3c434a",
                        fontSize: "13px",
                        lineHeight: "1.4em",
                        fontWeight: "bold",
                      }}
                    >
                      Shipping price
                    </Typography>
                  </Grid>
                  <Grid item md={1} xs={2} textAlign="center">
                    <CustomTypography>
                      {" "}
                      ${order.shipping_total}
                    </CustomTypography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container justifyContent="space-between" mt={1}>
                  <Grid item md={11} xs={10}>
                    <TypographyHeading>Total price</TypographyHeading>
                  </Grid>
                  <Grid item md={1} xs={2} textAlign="center">
                    <TypographyHeading> ${order.total}</TypographyHeading>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            <Paper
              sx={{ padding: "2rem 1rem", flex: 1 }}
              className={classes.fullWidth}
            >
              <Box sx={{ flex: 1 }} className={classes.fullWidth}>
                <Grid container justifyContent="space-between" mt={1}>
                  {paidOrder ? (
                    <TypographyHeading sx={{ mb: 2 }}>
                      Order is already fully paid
                    </TypographyHeading>
                  ) : (
                    <>
                      <Grid item md={7} xs={12}>
                        <TypographyHeading>
                          Update Payment Status
                        </TypographyHeading>
                      </Grid>
                      <Grid item md={5} xs={12} textAlign="center">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={"Unpaid"}
                          label="Payment Status"
                          onChange={() => {
                            handlePaymentChange(order.id, order.status);
                          }}
                        >
                          <MenuItem value={"Paid"}>Paid</MenuItem>
                          <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
                        </Select>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
              <TypographyHeading>Send payment link</TypographyHeading>
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    p: "2px 4px",
                    mt: 2,
                  }}
                  onClick={handlePaymentLink}
                >
                  Generate link
                </Button>
                {paymentLink && (
                  <>
                    <Box>
                      <a
                        href={`https://printyo.net.au/payment?orderId=${
                          order.id
                        }&cname=${order.billing?.first_name}&email=${
                          order && order.billing.email
                        }&phone=${order && order.billing.phone}&amount=${
                          order.total
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="payment-link"
                      >
                        {`https://printyo.net.au/payment?orderId=${
                          order.id
                        }&cname=${order.billing?.first_name}&email=${
                          order && order.billing.email
                        }&phone=${order && order.billing.phone}&amount=${
                          order.total
                        }`}
                      </a>
                    </Box>
                    <Stack direction="row" alignItems="center" my={2}>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontSize: "13px",
                          p: "2px 4px",
                        }}
                        onClick={copyURL}
                      >
                        {copy ? "Coped" : "Copy"}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontSize: "13px",
                          p: "2px 4px",
                          ml: 2,
                        }}
                      >
                        Send link
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>
              <Box>
                <Button
                  sx={{ textTransform: "none", fontSize: "15px", p: "2px 4px" }}
                  onClick={handleManualPayment}
                >
                  Add payment manually
                </Button>
                {payment && (
                  <Box>
                    <Elements stripe={stripePromise}>
                      <PaymentForm order={order} />
                    </Elements>
                  </Box>
                )}
              </Box>
            </Paper>
          </Stack>
          <PaymentDetails id={id} />
        </>
      )}
    </Container>
  );
};

export default Payment;
