import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  Container,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Mail from "./Mail";
import Note from "./Note";
import Sms from "./Sms";
import {
  getCustomerNotes,
  getCustomerExtraNotes,
  getEmail,
  getSms,
  sendInvoice,
} from "../../../api";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import TrackEvents from "../../../api/amplitudeEvents";
import Alert from "@mui/material/Alert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  activityBtnAll: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  activityEmailBox: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      overflowX: "auto",
    },
    "& #wrapper": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_quote": {
      paddingLeft: "0 !important",
      margin: "16px 0px 0px 0 !important",
    },
    "& .gmail_quote >div>div": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_signature *": {
      width: "auto !important",
    },
    "& table": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "500px !important",
      },
    },
  },
}));

const Activities = ({ order, id }) => {
  const classes = useStyles();
  const [category, setCategory] = useState(10);
  const [open, setOpen] = useState("");
  const [notes, setNotes] = useState([]);
  const [res, setRes] = useState(null);
  const [extraNotes, setExtraNotes] = useState([]);
  const [emails, setEmails] = useState([]);
  const [resEmail, setResEamil] = useState(false);
  const [sms, setSms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertString, setAlertString] = useState("");
  const [responseSeverity, setResponseSeverity] = useState("");
  const [alert, setAlert] = useState(false);
  const [invoiceSended, setInvoiceSended] = useState(false);
  const userid = localStorage.getItem("auth_id");

  const handleChange = useCallback((e) => {
    setCategory(e.target.value);
  }, []);

  const handleActivity = (value) => {
    if (value === open) {
      setOpen("");
    } else {
      setOpen(value);
    }
  };

  function createMarkup(data) {
    return { __html: data };
  }

  const handleInvoice = async () => {
    const data = new FormData();
    data.append("userid", userid);
    try {
      setLoading(true);
      const res = await sendInvoice(id, data);
      if (res.status === 200) {
        setAlert(true);
        setAlertString("Invoice sent successfully");
        setResponseSeverity("success");
        setLoading(false);
        setResEamil(!resEmail);
        setInvoiceSended(true);
      }
    } catch (error) {
      console.log("error from mockup", error);
      setLoading(false);
      setAlert(true);
      setAlertString("Something went wrong while sending mail to the user");
      setResponseSeverity("error");
      setInvoiceSended(false);
    }
  };

  useEffect(() => {
    const fetchNotes = async () => {
      const data = await getCustomerNotes(id);
      setNotes(data);
      console.log(notes);
    };
    const fetchExtraNotes = async () => {
      const data = await getCustomerExtraNotes(id);
      if (data.error === "none") {
        setExtraNotes(data.ordersnote);
        console.log(extraNotes);
      }
    };
    if (id) {
      fetchNotes();
      fetchExtraNotes();
    }
  }, [id, res]);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const data = await getEmail(id);
        setEmails(data.ordersnote);
      } catch (error) {
        console.log("eror from email activity", error);
      }
    };
    if (id) {
      fetchEmail();
    }
  }, [id, resEmail]);

  useEffect(() => {
    const fetchSms = async () => {
      const data = await getSms(id);
      setSms(data.ordersms);
    };
    if (id) {
      fetchSms();
    }
  }, [id]);

  return (
    <Container className={classes.container}>
      {alert ? (
        <Alert
          severity={responseSeverity}
          sx={{
            margin: "10px 0 20px",
          }}
        >
          {alertString}
        </Alert>
      ) : null}
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={3}
        flex={1}
        className={classes.activityBtnBox}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<EditIcon fontSize="small" />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            letterSpacing: "1px",
          }}
          value="note"
          onClick={() => handleActivity("note")}
        >
          Note
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<EmailIcon fontSize="small" />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            letterSpacing: "1px",
          }}
          value="email"
          onClick={() => handleActivity("email")}
        >
          Mail
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<SmsIcon fontSize="small" />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            letterSpacing: "1px",
          }}
          value="sms"
          onClick={() => handleActivity("sms")}
        >
          Sms
        </Button>

        {order.invoice_generated || invoiceSended ? (
          <Button
            variant="contained"
            size="small"
            startIcon={<FileCopyIcon fontSize="small" />}
            sx={{
              boxShadow: "none",
              textTransform: "none",
              letterSpacing: "1px",
            }}
          >
            <a
              href={`https://printyocrm.printyo.net.au/generate-invoice/${id}`}
              target="_blank"
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
            >
              Download Invoice
            </a>
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            startIcon={<FileCopyIcon fontSize="small" />}
            sx={{
              boxShadow: "none",
              textTransform: "none",
              letterSpacing: "1px",
            }}
            // onClick={() => handleInvoice()}
            disabled={loading}
          >
            {loading ? "Sending Invoice" : "Send Invoices"}
          </Button>
        )}

        <FormControl
          variant="outlined"
          className={classes.activityBtnAll}
          sx={{
            m: 1,
            minWidth: 80,
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={handleChange}
            size="small"
            sx={{ backgroundColor: "#fff" }}
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem value={10}>All activities</MenuItem>
            <MenuItem value={20}>Notes</MenuItem>
            <MenuItem value={30}>Mails</MenuItem>
            <MenuItem value={40}>Sms</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {open === "email" ? (
        <Mail order={order} setResEamil={setResEamil} resEmail={resEmail} />
      ) : open === "note" ? (
        <Note id={id} setRes={setRes} setOpen={setOpen} order={order} />
      ) : open === "sms" ? (
        <Sms {...order} />
      ) : null}
      {(category === 10 || category === 20) && (
        <Paper
          sx={{ p: 3, mt: 4, width: "70vw" }}
          className={classes.activityDetailBox}
        >
          <Typography>Notes</Typography>
          <Divider />
          {(notes || []).map((item) => {
            return (
              <Box key={item.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  {item.note}
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.date_created).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                  <span> By Printyo(Auto Generated)</span>
                </Box>
              </Box>
            );
          })}
          {(extraNotes || []).map((item) => {
            console.log(item);

            return (
              <Box key={item.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <Box>
                    <Box>
                      {item.ordernotetext && item.ordernotetext[0]
                        ? item.ordernotetext[0].text
                        : ""}
                    </Box>
                    <Box>
                      {item?.ordernoteattachments?.map((data) => {
                        return (
                          <Box
                            key={data?.id}
                            display="flex"
                            alignItems="center"
                          >
                            <CustomTypography sx={{ mr: 2 }}>
                              Image url:{" "}
                            </CustomTypography>
                            <a
                              href={`https://printyocrm.printyo.net.au/${data.attachmenturl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <CustomTypography sx={{ mr: 2 }}>
                                view img{" "}
                              </CustomTypography>
                            </a>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {item.ordernotetext && item.ordernotetext[0]
                    ? moment(item.ordernotetext[0].created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : ""}{" "}
                  <span>
                    {" "}
                    By{" "}
                    {item.ordernoteuser ? item.ordernoteuser.name : "Customer"}
                  </span>
                </Box>
              </Box>
            );
          })}
        </Paper>
      )}
      {(category === 10 || category === 30) && (
        <Paper
          sx={{ p: 3, mt: 4, width: "70vw" }}
          className={classes.activityDetailBox}
        >
          <Typography>Emails</Typography>
          <Divider />
          {(emails || []).map((item) => {
            return (
              <Box key={item.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <Box display="flex" alignItems="center">
                    <CustomTypography sx={{ mr: 2 }}>
                      Customer email
                    </CustomTypography>
                    <TypographySubHeading>
                      {item.emailactivity.customeremail}
                    </TypographySubHeading>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CustomTypography sx={{ mr: 2 }}>Subject</CustomTypography>
                    <TypographySubHeading>
                      {item.emailactivity.emailsubject}
                    </TypographySubHeading>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div
                      className={classes.activityEmailBox}
                      style={{ display: "inline-block" }}
                      dangerouslySetInnerHTML={createMarkup(
                        item.emailactivity.emailmessage
                      )}
                    ></div>
                  </Box>
                  <Box>
                    {item.emailactivityattachments.map((img) => {
                      return (
                        <Box key={img.id} display="flex" alignItems="center">
                          <CustomTypography sx={{ mr: 2 }}>
                            Image url:{" "}
                          </CustomTypography>
                          <a
                            href={`https://printyocrm.printyo.net.au/${img.attachmenturl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CustomTypography sx={{ mr: 2 }}>
                              view img{" "}
                            </CustomTypography>
                          </a>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.emailactivity.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  <span>
                    By{" "}
                    {item.emailactivityuser
                      ? item.emailactivityuser.name
                      : "Customer"}
                  </span>
                </Box>
              </Box>
            );
          })}
        </Paper>
      )}
      {(category === 10 || category === 40) && (
        <Paper
          sx={{ p: 3, mt: 4, width: "70vw" }}
          className={classes.activityDetailBox}
        >
          <Typography>SMS</Typography>
          <Divider />
          {(sms || []).map((item) => {
            return (
              <Box key={item.ordersms.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <div>{item.ordersms.smsmessage}</div>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.ordersms.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  <span>
                    {" "}
                    By{" "}
                    {item.ordersmsuser
                      ? item.ordersmsuser.name
                      : "Deleted Employee"}
                  </span>
                </Box>
              </Box>
            );
          })}
        </Paper>
      )}
    </Container>
  );
};

export default Activities;
